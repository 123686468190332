import React, { useEffect } from 'react';
import { TopSection } from '@sections/TopSection';
import BackgroundImg from '@assets/images/balloon.png';
import { PageSection } from '@pages/home/homeStyles';
import { Footer } from '@components/Footer';
import { BannerText } from '@styles/globalStyles';
import { TextCycle } from '@components/TextCycle';
import { ProjectDescription } from '@components/ProjectCard/projectCardStyles';
import { colors, dimensions } from '@styles/theme';
import SketchResults from '@assets/images/ix-study/sketch-frequency.png';
import DiscusNotes from '@assets/images/ix-study/discus-notes.png';
import Personas from '@assets/images/ix-study/personas.png';
import Sketches from '@assets/images/ix-study/sketches.jpg';
import UserFlow from '@assets/images/ix-study/user-flow.png';
import Blockframes from '@assets/images/ix-study/blockframes.png';
import Prototypes from '@assets/images/ix-study/prototypes.png';
import Gif1 from '@assets/images/ix-study/ix-1.gif';
import Gif2 from '@assets/images/ix-study/ix-2.gif';
import Gif3 from '@assets/images/ix-study/ix-3.gif';
import Gif4 from '@assets/images/ix-study/ix-4.gif';
import TextLogo from '@assets/images/ix-study/ix-text-logo.png';
import IxLogo from '@assets/images/ix-study/ix-logo-icon.svg';
import {
  ArrowLeftCircle,
  ArrowUpRight,
  Award,
  CheckCircle,
  TrendingUp,
  User,
  Users,
} from 'react-feather';
import { CarouselWrapper } from '@components/Carousel';
import {
  BodyContainer,
  BodyCopy,
  BodySubtitle,
  BodyTitle,
  BodyColumn,
  BodyImage,
  BodyRow,
  BodySection,
  IconIcon,
  IconItem,
  IconList,
  IconSublist,
  IconListTall,
  BodyColumnWide,
  TitleLogo,
  SiteLink,
  ImageContainer,
} from '@components/Article/articleStyles';
import { Marginer } from '@components/Marginer';
import { ArticleNav } from '@components/ArticleNav';

const keyFeatures = [
  'Part Repository',
  'Part Creation Wizard',
  'Progression Indicators',
  'Conversational Prompts',
  'Feature List',
  'Directional Sequencing',
  'Feature Review',
  'Feature Verification',
  'Autoballooning',
  'Document Controls',
  'Extraction Toolbar',
  'Side Navigation',
];

const productState = [
  '2,000 Parts Digitized',
  '90,000 Features Verified',
  '2,000 Reports Generated',
  'Reached 100 NPS Score',
  '57 Active Paid Users',
  '$2,195 ASP',
  '$90,000 ARR',
];

const gifs = [
  {
    src: Gif1,
    label: 'Upload Drawings',
  },
  {
    src: Gif2,
    label: 'Part Workflow',
  },
  {
    src: Gif3,
    label: 'Customize Elements',
  },
  {
    src: Gif4,
    label: 'Extract Features',
  },
];

const team = [
  { label: 'Product Lead', subitems: [] },
  { label: 'Product Manager', subitems: [] },
  {
    label: 'Product Designer (me)',
    subitems: [
      'Research',
      'User Flows',
      'UI/UX Design',
      'Prototyping',
      'User Validation',
    ],
  },
  {
    label: '5 Developers (me)',
    subitems: ['Frontend', 'Backend', 'Infrastructure', 'Testing'],
  },
];

const welcomeContent = (
  <>
    <BannerText>InspectionXpert Cloud</BannerText>
    <TextCycle
      beforeText=""
      cycleText={['Product Designer', 'Full Stack Developer']}
      afterText="."
    />
    <ProjectDescription>Sept. 2019 - Current</ProjectDescription>
  </>
);

export const InspectionXpert = () => {
  useEffect(() => {
    const timer = setTimeout(() => {
      const container = document.getElementById('page-wrapper');
      if (container) container.scrollTo({ top: 0, left: 0 });
    }, 100);

    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      <TopSection
        nextSectionName="study"
        backgroundUrl={BackgroundImg}
        logoUrl={IxLogo}
        variant="ix-logo"
        buttonText="Read case study"
        welcomeContents={welcomeContent}
      />
      <PageSection id="study" name="study">
        <BodyContainer>
          <BodySection bgColor={colors.background}>
            <BodyTitle>The Project</BodyTitle>
            <TitleLogo alt="ix-text-logo" src={TextLogo} />
            <BodyRow>
              <BodyColumnWide>
                <BodySubtitle>Overview</BodySubtitle>
                <BodyCopy>
                  InspectionXpert OnDemand (IXOD) had lost the ability to
                  reliably develop its windows platform and release new features
                  in a way that made customers confident and satisfied. Our
                  customers were finding more modern solutions in the market and
                  we were failing to meet their needs.
                </BodyCopy>
                <BodyCopy>
                  Our goal was to do a complete redesign of IXOD as a
                  cloud-based web platform so we could best serve our customers
                  with the latest technology, continuous deployment, and a
                  modern user experience. We aimed to bring them a modern
                  solution so that they could solve the problems in their
                  business with pleasent efficiency.
                </BodyCopy>
              </BodyColumnWide>

              <BodyColumn>
                <BodySubtitle>Team</BodySubtitle>
                <IconListTall>
                  {team.map((t, index) => (
                    <div key={`${t.label}-${index}-section-key`}>
                      <IconItem key={`${t.label}-${index}-key`}>
                        <IconIcon>
                          {parseInt(t.label) > 0 ? (
                            <Users height={16} width={16} />
                          ) : (
                            <User height={16} width={16} />
                          )}
                        </IconIcon>
                        {t.label}
                      </IconItem>
                      {t.subitems && t.subitems.length > 0 && (
                        <IconSublist>
                          {t.subitems.map((s, index) => (
                            <IconItem key={`${s}-${index}-key`}>
                              <IconIcon>
                                <CheckCircle height={14} width={14} />
                              </IconIcon>
                              {s}
                            </IconItem>
                          ))}
                        </IconSublist>
                      )}
                    </div>
                  ))}
                </IconListTall>
              </BodyColumn>
            </BodyRow>
          </BodySection>
          <BodySection bgColor={colors.brand}>
            <BodyTitle>Researching</BodyTitle>
            <BodySubtitle>The Customer</BodySubtitle>
            <BodyRow>
              <BodyCopy>
                InspectionXpert was able to leverage its stellar reputation with
                its customers to put together a 10 member advisory group dubbed
                "The Captains". We also had a handful of local machine shops in
                the area that were happy to work with us and show off their
                operation. Seeing how our customers used our product in the
                field as well as how it fit into their overall structure was
                incredibly helpful in building necessary context.
              </BodyCopy>
              <BodyCopy>
                Our target market consisted of high precision machine shops
                typically in the automotive, medical, aviation, or defense
                industries. The main user of the application is the Quality
                Manager of these shops, abstracted into the persona "Quality
                Cal", though the tasks involved can end up in the hands of the
                shop owner or administrator. These shops were generally split
                into two demographics, young and tech savvy looking to stay on
                the bleeding edge of tech in their business, or experienced with
                an ingrained methodology that oftentimes made use of manual
                processes. We created a series of User Personas to help keep us
                focused on the people we were building for.
              </BodyCopy>
            </BodyRow>
            <ImageContainer>
              <BodyImage backgroundUrl={Personas} />
            </ImageContainer>
          </BodySection>

          <BodySection bgColor={colors.background}>
            <BodySubtitle>The Competition</BodySubtitle>
            <BodyRow>
              <BodyCopy>
                We began with an audit of our product's competition within the
                market including our own platform which we would inevitably be
                competing with. The competitors we looked at were Discus,
                HighQA, 1Factory, and IXOD. I analyzed each website and demo'd
                their products when possible to gain a sense of how they
                provided value to the user. I created an empathy map of each
                product based on customer reviews and laid out the main flow of
                tasks for how they took their user from start to value. The
                final competitor we were up against was old fashioned pen and
                paper.
              </BodyCopy>
              <BodyCopy>
                Many shops were still printing out their drawings, adding
                balloons by hand, and manually entering all of the feature data
                into spreadsheets. Using this research we gaged which features
                were most and least valuable to the market and noted where the
                competition succeeded and failed. We noted where competitors
                were currently ahead of us, what segment of the market they were
                targeting, what features were expected, pleasers, and
                differentiators, and where the best opportunity for us existed.
              </BodyCopy>
            </BodyRow>
            <ImageContainer>
              <BodyImage backgroundUrl={DiscusNotes} />
            </ImageContainer>
          </BodySection>

          <BodySection bgColor={colors.brand}>
            <BodyTitle>Brainstorming</BodyTitle>
            <BodySubtitle>The Sketching</BodySubtitle>
            <BodyRow>
              <BodyCopy>
                This sketching session was a cross-organizational workshop that
                had members sketch ideas in two sessions. We began with some Q&A
                so that we could extract the relevant information from different
                sectors of the business. Sales, marketing, support, and
                development all have interactions with customers from differing
                perspectives. It was important that we uncovered these insights
                before beginning the sketches.
              </BodyCopy>
              <BodyCopy>
                The sketching was split into two sessions in which particpants
                could draw whatever ideas they wanted. Sketches were kept low on
                detail so that as many ideas could be surfaced as possible.
                After the first session each person presented their sketches and
                we shared what was liked and disliked. For the second session
                each person drew on ideas from the others and refined their
                original sketches.
              </BodyCopy>
            </BodyRow>
            <ImageContainer>
              <BodyImage backgroundUrl={Sketches} />
            </ImageContainer>
          </BodySection>

          <BodySection bgColor={colors.background}>
            <BodySubtitle>The Analysis</BodySubtitle>
            <BodyCopy>
              Following the sketching session I went over the notes and each
              member's ideas and charted the frequency of those ideas to get an
              idea of what concepts we coalesced around. We also took note of
              which ideas showed up more or less in the second session compared
              to the first. I sat with the team Product/Tech Lead and went
              through the sketches again along with the notes until we were able
              to nail down a list of core features that would be necessary for
              the product.
            </BodyCopy>
            <BodyCopy>Some of the key features we landed on were:</BodyCopy>
            <IconList>
              {keyFeatures.map((feature, index) => (
                <IconItem key={`${feature}-${index}-item-key`}>
                  <IconIcon>
                    <Award height={16} width={16} />
                  </IconIcon>
                  {feature}
                </IconItem>
              ))}
            </IconList>
            <Marginer direction="vertical" margin="1.5em" />
            <ImageContainer>
              <BodyImage backgroundUrl={SketchResults} />
            </ImageContainer>
          </BodySection>

          <BodySection bgColor={colors.brand}>
            <BodyTitle>Mapping</BodyTitle>
            <BodySubtitle>The Flows</BodySubtitle>
            <BodyRow>
              <BodyCopy>
                One of the core features of this version of InspectionXpert
                would be the wizard-like process of creating virtual parts. In
                IXOD the application was much more like a sandbox - the user was
                dropped in with tools strewn about, some buried in the sand, and
                left to their own devices. As a result the users often struggled
                to complete tasks, missed filling in necessary data, or failed
                to discover certain functionality existed at all.
              </BodyCopy>
              <BodyCopy>
                I mapped out several competing user flows starting with simple
                tasks before expanding into each individual action. The result
                was three competing flows with similar structures but varying
                levels of complexity. The flows were reviewed with the team
                until we decided to move forward with the flow that kept the
                process as simple as possible for the user while still being
                able to achieve their core tasks.
              </BodyCopy>
            </BodyRow>
            <ImageContainer>
              <BodyImage backgroundUrl={UserFlow} />
            </ImageContainer>
          </BodySection>

          <BodySection bgColor={colors.background}>
            <BodySubtitle>The Blockframes</BodySubtitle>
            <BodyCopy>
              With the user flow mapped out it was time to start designing the
              interface. We began with blockframes, low fidelity wireframes that
              we used to get the general sense for how pages would be laid out
              and how they would be navigated. Elements of the page were
              represented in plain blocks and details were kept to a minimum.
              This allowed us to get to failure quickly and iterate until we
              found a general structure we were satisfied with.
            </BodyCopy>
            <ImageContainer>
              <BodyImage backgroundUrl={Blockframes} />
            </ImageContainer>
          </BodySection>

          <BodySection bgColor={colors.brand}>
            <BodyTitle>Prototyping</BodyTitle>
            <BodySubtitle>The Testing</BodySubtitle>
            <BodyRow>
              <BodyCopy>
                The next step was to fill in the missing fidelity from the
                previous blockframes and wireframes and create high fidelity
                clickable mockups in Figma. We brought these prototypes to our
                Captains and had them step through the flows in structured
                testing sessions. We looked out for points they got stuck on or
                distracted from their task and tracked how many clicks it took
                them to complete objectives. We also asked for qualitative
                feedback to get their general impressions, their likes and
                dislikes, and what they though was missing.
              </BodyCopy>
              <BodyCopy>
                Using their feedback we went through a series of redesigns to
                simplify the process and remove excess interactions. We improved
                layouts and adjusted the messaging where necessary to improve
                understanding. The main flow we crafted received positive
                reviews and the users were able to complete the tasks as
                designed, all that remained was some fine-tuning before we could
                turn to development.
              </BodyCopy>
            </BodyRow>
            <ImageContainer>
              <BodyImage backgroundUrl={Prototypes} />
            </ImageContainer>
          </BodySection>

          <BodySection bgColor={colors.background}>
            <BodyTitle>Building</BodyTitle>
            <BodySubtitle>The Finished Product</BodySubtitle>
            <BodyCopy>
              Throughout the next year we put code to code editor and created
              the first working version of InspectionXpert Cloud. We returned to
              our Captains and ran a beta program so that we could refine our
              work and redesign where necessary to make the product ready for
              the market. Thanks to all of the hard work from the team and the
              support of the rest of the organization, we successfully launched
              InspectionXpert Cloud in April, 2020.
            </BodyCopy>
            <BodyColumn>
              <BodySubtitle>State of the Product</BodySubtitle>
              <IconList>
                {productState.map((feature, index) => (
                  <IconItem key={`${feature}-${index}-item-key`}>
                    <IconIcon>
                      <TrendingUp height={16} width={16} />
                    </IconIcon>
                    {feature}
                  </IconItem>
                ))}
              </IconList>
            </BodyColumn>
            <Marginer direction="vertical" margin="1.5em" />
            <CarouselWrapper gifs={gifs} />
            <Marginer direction="vertical" margin="1em" />
            <SiteLink
              href="https://www.inspectionxpert.com/inspectionxpert"
              target="_blank"
            >
              Visit InspectionXpert for More
              <ArrowUpRight />
            </SiteLink>
          </BodySection>
          <ArticleNav
            prevText="Home"
            prevPath="/"
            nextText="Inspection Reports"
            nextPath="inspectionreports"
          />
        </BodyContainer>
      </PageSection>
      <Footer />
    </>
  );
};
