import React, { useEffect } from 'react';
import { Footer } from '@components/Footer';
import { Marginer } from '@components/Marginer';
import { PdfViewer } from '@components/PdfViewer';
import { PageSection } from '@pages/home/homeStyles';
import { ArticleNav } from '@components/ArticleNav';

export const Resume = () => {
  useEffect(() => {
    const timer = setTimeout(() => {
      const container = document.getElementById('page-wrapper');
      if (container) container.scrollTo({ top: 0, left: 0 });
    }, 100);

    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      <PageSection name="resume">
        <Marginer direction="vertical" margin="4em" />
        <PdfViewer />
      </PageSection>
      <ArticleNav
        prevText="About Me"
        prevPath="/about"
        nextText="Home"
        nextPath="/"
      />
      <Footer />
    </>
  );
};
