import React, { useEffect } from 'react';
import { TopSection } from '@sections/TopSection';
import BackgroundImg from '@assets/images/report-study/template.png';
import { PageSection } from '@pages/home/homeStyles';
import { Footer } from '@components/Footer';
import { BannerText } from '@styles/globalStyles';
import { TextCycle } from '@components/TextCycle';
import { ProjectDescription } from '@components/ProjectCard/projectCardStyles';
import { colors } from '@styles/theme';
import Rubric from '@assets/images/report-study/rubric.jpeg';
import Mockups from '@assets/images/report-study/mockups.png';
import UserFlow from '@assets/images/report-study/flows.png';
import Blockframes from '@assets/images/report-study/report-blockframes.png';
import Redesigns from '@assets/images/report-study/redesigns.png';
import Gif1 from '@assets/images/report-study/report-gen.gif';
import Gif2 from '@assets/images/report-study/filter.gif';
import Gif3 from '@assets/images/report-study/tokenize.gif';
import Gif4 from '@assets/images/report-study/access.gif';
import TextLogo from '@assets/images/ix-study/ix-text-logo.png';
import IxLogo from '@assets/images/ix-study/ix-logo-icon.svg';
import {
  ArrowUpRight,
  CheckCircle,
  Sunrise,
  ThumbsDown,
  User,
  Users,
} from 'react-feather';
import { CarouselWrapper } from '@components/Carousel';
import {
  BodyContainer,
  BodyCopy,
  BodySubtitle,
  BodyTitle,
  BodyColumn,
  BodyImage,
  BodyRow,
  BodySection,
  IconIcon,
  IconItem,
  IconList,
  IconSublist,
  IconListTall,
  BodyColumnWide,
  TitleLogo,
  SiteLink,
  IconItemWide,
  ImageContainer,
} from '@components/Article/articleStyles';
import { Marginer } from '@components/Marginer';
import { ArticleNav } from '@components/ArticleNav';

const frictionPoints = [
  'Footer Management',
  'Single vs. Dynamic Tokens',
  'Report Preview Confusion',
  'Excessive Steps',
  'Action Discoverability',
];

const keyImprovements = [
  { label: 'Allow users to preview templates with dummy data', subitems: [] },
  {
    label: 'Use a template gallery to display available templates',
    subitems: [],
  },
  { label: 'Merge steps to reduce unecessary progression', subitems: [] },
  {
    label: 'Make report action buttons larger with greater contrast',
    subitems: [],
  },
  { label: 'Remove the report preview to avoid confusion', subitems: [] },
  {
    label: 'Include tips and hints explaining Single and Dynamic data',
    subitems: [],
  },
];

const gifs = [
  {
    src: Gif1,
    label: 'Generate Reports',
  },
  {
    src: Gif2,
    label: 'Filter Data',
  },
  {
    src: Gif3,
    label: 'Build Templates',
  },
  {
    src: Gif4,
    label: 'Access and Edit',
  },
];

const team = [
  { label: 'Product Lead', subitems: [] },
  { label: 'Marketing Lead', subitems: [] },
  { label: 'Support Specialist', subitems: [] },
  {
    label: 'Product Designer (me)',
    subitems: [
      'Research',
      'User Flows',
      'UI/UX Design',
      'Prototyping',
      'User Validation',
    ],
  },
  {
    label: '2 Developers (me)',
    subitems: ['Frontend', 'Backend', 'Testing'],
  },
];

const welcomeContent = (
  <>
    <BannerText>InspectionXpert Cloud - Inspection Reports</BannerText>
    <TextCycle
      beforeText=""
      cycleText={['Product Designer', 'Full Stack Developer']}
      afterText="."
    />
    <ProjectDescription>May 2020 - May 2021</ProjectDescription>
  </>
);

export const InspectionReports = () => {
  useEffect(() => {
    const timer = setTimeout(() => {
      const container = document.getElementById('page-wrapper');
      if (container) container.scrollTo({ top: 0, left: 0 });
    }, 100);

    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      <TopSection
        nextSectionName="study"
        backgroundUrl={BackgroundImg}
        logoUrl={IxLogo}
        variant="ix-logo"
        buttonText="Read case study"
        welcomeContents={welcomeContent}
      />
      <PageSection id="study" name="study">
        <BodyContainer>
          <BodySection bgColor={colors.background}>
            <BodyTitle>The Project</BodyTitle>
            <TitleLogo alt="ix-text-logo" src={TextLogo} />
            <BodyRow>
              <BodyColumnWide>
                <BodySubtitle>Overview</BodySubtitle>
                <BodyCopy>
                  Report generation is the event we marked as the main point of
                  value that a user reaches in InspectionXpert Cloud. These
                  reports are required by our user's customers and are
                  standardized to some degree within industries. The most common
                  standards followed by our users are FAI (first article
                  inspection) and PPAP (ppap).
                </BodyCopy>
                <BodyCopy>
                  Our goal was to implement a reporting feature in
                  InspectionXpert Cloud that allowed users to create their own
                  templates and generate reports from their part data. We did
                  not expect to act as a replacement for Excel, instead the
                  objective was to allow users to import their sheets and
                  tokenize them with our template building interface.
                </BodyCopy>
              </BodyColumnWide>

              <BodyColumn>
                <BodySubtitle>Team</BodySubtitle>
                <IconListTall>
                  {team.map((t, index) => (
                    <div key={`${t.label}-${index}-section-key`}>
                      <IconItem key={`${t.label}-${index}-key`}>
                        <IconIcon>
                          {parseInt(t.label) > 0 ? (
                            <Users height={16} width={16} />
                          ) : (
                            <User height={16} width={16} />
                          )}
                        </IconIcon>
                        {t.label}
                      </IconItem>
                      {t.subitems && t.subitems.length > 0 && (
                        <IconSublist>
                          {t.subitems.map((s, index) => (
                            <IconItem key={`${s}-${index}-key`}>
                              <IconIcon>
                                <CheckCircle height={14} width={14} />
                              </IconIcon>
                              {s}
                            </IconItem>
                          ))}
                        </IconSublist>
                      )}
                    </div>
                  ))}
                </IconListTall>
              </BodyColumn>
            </BodyRow>
          </BodySection>

          {/* <BodySection bgColor={colors.brand}>
            <BodyTitle>Brainstorming</BodyTitle>
            <BodySubtitle>The Sketching</BodySubtitle>
            <BodyRow>
              <BodyCopy>
                This sketching session was a cross-organizational workshop that
                had members sketch ideas in two sessions. We began with some Q&A
                so that we could extract the relevant information from different
                sectors of the business. Sales, marketing, support, and
                development all have interactions with customers from differing
                perspectives. It was important that we uncovered these insights
                before beginning the sketches.
              </BodyCopy>
              <BodyCopy>
                The sketching was split into two sessions in which particpants
                could draw whatever ideas they wanted. Sketches were kept low on
                detail so that as many ideas could be surfaced as possible.
                After the first session each person presented their sketches and
                we shared what was liked and disliked. For the second session
                each person drew on ideas from the others and refined their
                original sketches.
              </BodyCopy>
            </BodyRow>
            <ImageContainer>
              <BodyImage backgroundUrl={Sketches} />
            </ImageContainer>
          </BodySection> */}

          <BodySection bgColor={colors.brand}>
            <BodyTitle>Mapping</BodyTitle>
            <BodySubtitle>The Flows</BodySubtitle>
            <BodyRow>
              <BodyCopy>
                The part digitizer flow had already been built and implemented.
                The reporting flow would exist as the next series of tasks a
                user is expected to complete. We needed to map how and when this
                flow was entered, what steps were required to complete it, and
                how the user would navigate through their tasks.
              </BodyCopy>
              <BodyCopy>
                The tasks of generating a report and creating a report template
                were split apart so that they could be accessed individually
                while including a bridge between them. Template creation was a
                low frequency event, but it may be necessary to jump into it
                when a user is creating a report for the first time. These flows
                were reviewed and refined with the team until a consensus was
                reached.
              </BodyCopy>
            </BodyRow>
            <ImageContainer>
              <BodyImage backgroundUrl={UserFlow} />
            </ImageContainer>
          </BodySection>

          <BodySection bgColor={colors.background}>
            <BodySubtitle>The Blockframes</BodySubtitle>
            <BodyCopy>
              We utilized the same process that we used previously to build out
              rough layouts for the screens involved in this flow. This allowed
              us to understand which elements were necessary for each screen and
              what actions the user would need to complete. There needed to be
              an interface for choosing templates, a viewer to display reports
              in a excel-like format, and options to create, edit, or clone new
              templates.
            </BodyCopy>
            <ImageContainer>
              <BodyImage backgroundUrl={Blockframes} />
            </ImageContainer>
          </BodySection>

          <BodySection bgColor={colors.brand}>
            <BodyTitle>Designing</BodyTitle>
            <BodySubtitle>The Mockups</BodySubtitle>
            <BodyRow>
              <BodyCopy>
                I used Figma to convert our blockframes and flows into high
                fidelity mockups. We were able to leverage the design system and
                component library we previous created to speed up this process.
                This let us iterate through several versions of the mockups as
                we designed and refined. The process we used followed the "rule
                of 3" as we called it. The progress from initial designs to
                something we could move forward with generally took three design
                review sessions. These sessions consisted of myself and the
                product lead stepping through the Figma designs and critiquing
                them with the user and our objectives in mind.
              </BodyCopy>
            </BodyRow>
            <ImageContainer>
              <BodyImage backgroundUrl={Mockups} />
            </ImageContainer>
          </BodySection>

          <BodySection bgColor={colors.background}>
            <BodyTitle>Releasing</BodyTitle>
            <BodySubtitle>The First Version</BodySubtitle>
            <BodyCopy>
              When the development work on the feature was complete we were able
              to release it to our users. We utilized in-app analytics tools
              such as Amplitude and Appcues to track user progress and see where
              and when they ran into issues. We also connected with our users
              through calls and screen sharing sessions to ask them more
              directly about their experience with the reporting feature.
            </BodyCopy>
            <BodyCopy>
              Our support specialist created a grading rubric that was able to
              capture the discreet actions and tasks that were causing the most
              friction for our users. We used this to identify interactions and
              interface elements that could be improved and we began a redesign
              process. Here are some of the friction points we identified:
            </BodyCopy>
            <IconList>
              {frictionPoints.map((point, index) => (
                <IconItem key={`${point}-${index}-item-key`}>
                  <IconIcon>
                    <ThumbsDown height={16} width={16} />
                  </IconIcon>
                  {point}
                </IconItem>
              ))}
            </IconList>
            <Marginer direction="vertical" margin="1.5em" />
            <ImageContainer>
              <BodyImage backgroundUrl={Rubric} />
            </ImageContainer>
          </BodySection>

          <BodySection bgColor={colors.brand}>
            <BodyTitle>Redesigning</BodyTitle>
            <BodySubtitle>The Mockups</BodySubtitle>
            <BodyCopy>
              With our research and discussions in hand we returned to Figma to
              make our revisions. There were several key improvements we agreed
              were necessary before jumping back into the designs:
            </BodyCopy>
            <IconListTall>
              {keyImprovements.map((improvement, index) => (
                <div key={`${improvement.label}-${index}-section-key`}>
                  <IconItemWide key={`${improvement.label}-${index}-key`}>
                    <IconIcon>
                      <Sunrise height={16} width={16} />
                    </IconIcon>
                    {improvement.label}
                  </IconItemWide>
                </div>
              ))}
            </IconListTall>
            <BodyCopy>
              We designed two variations of the improved feature and went
              through another series of design reviews.
            </BodyCopy>
            <ImageContainer>
              <BodyImage backgroundUrl={Redesigns} />
            </ImageContainer>
          </BodySection>

          <BodySection bgColor={colors.background}>
            <BodyTitle>Unveiling</BodyTitle>
            <BodySubtitle>The Redesign</BodySubtitle>
            <BodyCopy>
              With the design work complete we moved on to the development stage
              of the product. Once complete we were able to release the second
              version of the reporting feature to our users. Our support team
              contacted users who had previously had issues with the reporting
              interface and received positive feedback on the improvements.
              There was a noticeable decrease in reporting related support
              tickets and we saw an increase in NPS scores following the
              release.
            </BodyCopy>
            <CarouselWrapper gifs={gifs} />
            <Marginer direction="vertical" margin="1em" />
            <SiteLink
              href="https://www.inspectionxpert.com/inspectionxpert"
              target="_blank"
            >
              Visit InspectionXpert for More
              <ArrowUpRight />
            </SiteLink>
          </BodySection>

          <ArticleNav
            prevText="InspectionXpert Cloud"
            prevPath="/inspectionxpert"
            nextText="Paradox Games"
            nextPath="arcade"
          />
        </BodyContainer>
      </PageSection>
      <Footer />
    </>
  );
};
