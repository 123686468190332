import React from 'react';
import useScript from '@hooks/useScript';
import { asteroidsScript } from './asteroidsScript';
import { engine } from '../paradoxEngine';
import { CanvasContainer } from '../paradoxStyles';

export const AsteroidsGame = () => {
  useScript([
    engine,
    asteroidsScript,
    `preloadArtAssets('../assets/images/', IMAGES, initializeEngine);
    callbackOnEngineLoad(initializeGame);`,
  ]);

  return (
    <CanvasContainer>
      <canvas
        id="canvas"
        width="800"
        height="600"
        style={{ border: '1px solid #c3c3c3' }}
      >
        Your browser does not support the HTML5 canvas tag.
      </canvas>

      <div>
        <span id="upgradeLevel"> Upgrade Level: 1 shot</span>
      </div>
    </CanvasContainer>
  );
};
