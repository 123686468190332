import React from 'react';
import styled from 'styled-components';
import { colors, dimensions, effects } from '@styles/theme';

interface TabsProps {
  handleSwitchTab: (option: string) => void;
  options: string[];
  selected: string;
}

const TabBar = styled.div`
  display: flex;
  flex-direction: row;
  width: fit-content;
`;

const Tab = styled.div`
  height: ${dimensions.buttonHeight};
  line-height: ${dimensions.buttonHeight};
  padding: 0 8px;
  background-color: white;
  color: ${colors.brand};
  border: 1px solid ${colors.brand};
  cursor: pointer;

  &.selected {
    font-weight: 600;
    background-color: ${colors.brand};
    color: white;
  }

  &:hover {
    filter: ${effects.hoverFilter};
  }
`;

export const Tabs = ({ handleSwitchTab, options, selected }: TabsProps) => {
  return (
    <TabBar>
      {options.map((option: string) => {
        return (
          <Tab
            onClick={(e: any) => handleSwitchTab(option)}
            className={selected === option ? 'selected' : ''}
          >
            {option}
          </Tab>
        );
      })}
    </TabBar>
  );
};
