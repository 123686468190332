import styled from 'styled-components';
import { animations, colors, effects } from '@styles/theme';

export const FooterLinksContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 70%;
  height: 2em;

  @media screen and (max-width: 480px) {
    width: 90%;
  }
`;

export const FooterLinks = styled.div`
  font-size: 1.25em;
  font-weight: 500;
  color: ${colors.text};
  display: flex;
  flex-direction: row;
  gap: 8px;
  transition: ${animations.smoothTransition};

  &:hover {
    cursor: pointer;
    filter: ${effects.hoverFilter};
  }
`;

export const FooterLine = styled.hr`
  width: 70%;
  color: ${colors.text};

  @media screen and (max-width: 480px) {
    width: 90%;
  }
`;

export const FooterCopyright = styled.p`
  font-size: 1em;
  color: ${colors.offText};
`;

export const FooterContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${colors.background};
  padding: 1em 0;
`;
