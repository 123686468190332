import styled from 'styled-components';

export const ProjectsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  padding: 0.5em;
  align-items: center;
  grid-area: content;
  justify-content: center;
  gap: 1em;

  @media screen and (max-width: 480px) {
    width: 90%;
  }
`;
