import styled from 'styled-components';
import { colors, dimensions } from '@styles/theme';

export const TopContainer = styled.div`
  width: 100%;
  height: 100vh;
  padding: 0;
  background-image: ${({ backgroundUrl }: { backgroundUrl: string }) =>
    `url(${backgroundUrl})`};
  background-position: center;
  background-size: cover;
`;

export const TopContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  height: 100%;
  width: 70%;
  margin: 0 auto;
  padding: 5em 0;

  @media screen and (max-width: 480px) {
    padding: 2em 1em;
    width: 100%;
  }
`;

export const IntroContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  flex: 2 1;

  @media screen and (max-width: 480px) {
    flex-direction: column;
    gap: 8px;
    align-items: center;
    width: 100%;
  }
`;

export const IntroMessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
`;

export const ColumnSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: fit-content;
  padding: 8px 0;
  gap: 8px;

  @media screen and (max-width: 480px) {
    height: fit-content;
  }
`;

export const FeatureContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: end;
  gap: 1em;
  padding: 8px 0;
  flex: 1 1;

  @media screen and (max-width: 480px) {
    width: auto;
    align-items: center;
  }
`;

export const FeaturedTitle = styled.div`
  padding: 4px 8px;
  width: 100%;
  text-align: center;
  font-size: 1em;
  color: ${colors.background};
  background-color: ${colors.brand};
`;

// Filter that goes over background image to give it shaded look
export const ContainerBackgroundFilter = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(55, 55, 55, 0.65);
  border-radius: inherit;
`;
