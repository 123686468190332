import React from 'react';
import { PageSection } from '@pages/home/homeStyles';
import { GameContainer } from '../paradoxStyles';
import { AsteroidsDocs } from './AsteroidsDocs';
import { AsteroidsGame } from './AsteroidsGame';

export const Asteroids = () => {
  return (
    <PageSection name="asteroids">
      <GameContainer>
        <AsteroidsGame />
        <AsteroidsDocs />
      </GameContainer>
    </PageSection>
  );
};
