import React from 'react';
import useScript from '@hooks/useScript';
import { engine } from '../paradoxEngine';
import { alchemonScript } from './alchemonScript';
import { CanvasContainer } from '../paradoxStyles';

export const AlchemonGame = () => {
  useScript([
    engine,
    alchemonScript,
    `
        preloadArtAssets('../assets/images/', IMAGES, initializeEngine);
        callbackOnEngineLoad(initializeGame);
    `,
  ]);

  return (
    <CanvasContainer>
      <canvas id="canvas" width="400" height="600">
        Your browser does not support the HTML5 canvas tag.
      </canvas>
    </CanvasContainer>
  );
};
