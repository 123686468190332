import React from 'react';
import { PageSection } from '@pages/home/homeStyles';
import { GameContainer } from '../paradoxStyles';
import { CopsDocs } from './CopsDocs';
import { CopsGame } from './CopsGame';

export const Cops = () => {
  return (
    <PageSection name="asteroids">
      <GameContainer>
        <CopsGame />
        <CopsDocs />
      </GameContainer>
    </PageSection>
  );
};
