import React from 'react';
import { ButtonText, ButtonWrapper } from './buttonStyles';

interface ButtonProps {
  variant: 'primary' | 'secondary' | 'danger' | 'wide';
  text: string;
  onClick: (e: any) => void;
  icon?: any;
}

export const Button = ({ variant, text, onClick, icon }: ButtonProps) => {
  return (
    <ButtonWrapper variant={variant} onClick={onClick}>
      {icon && icon}
      <ButtonText>{text}</ButtonText>
    </ButtonWrapper>
  );
};
