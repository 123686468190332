import React, { useState } from 'react';
import { Download, Loader } from 'react-feather';
import { Document, Page, pdfjs } from 'react-pdf/dist/esm/entry.webpack';
import { Button } from '@components/Button';
import { Marginer } from '@components/Marginer';
import { Tabs } from '@components/Tabs';
import { saveAs } from 'file-saver';
import { PdfTools, Viewer } from './pdfViewerStyles';
import resumeDark from '@assets/resume/resume-dark.pdf';
import resumeLight from '@assets/resume/resume-light.pdf';
import pragmatic from '@assets/resume/pragmatic.pdf';
import { BodyTitle } from '@components/Article/articleStyles';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export const PdfViewer = () => {
  const [selected, setSelected] = useState('dark');
  const [file, setFile] = useState(resumeDark);

  const handleSwitchTab = (option: string) => {
    setSelected(option);
    setFile(option === 'dark' ? resumeDark : resumeLight);
  };

  const handleDownload = () => {
    saveAs(file, `ryan-mee-resume-${selected}.pdf`);
  };

  return (
    <>
      <Viewer>
        <Document
          file={file}
          onLoadError={console.error}
          className="pdf-viewer"
          loading={<Loader />}
          options={{ workerSrc: '/pdf.worker.js' }}
        >
          <PdfTools>
            <Tabs
              options={['dark', 'light']}
              selected={selected}
              handleSwitchTab={handleSwitchTab}
            />
            <Button
              variant="secondary"
              onClick={handleDownload}
              icon={<Download />}
              text="download"
            />
          </PdfTools>
          <Marginer margin="1em" direction="vertical" />
          <Page scale={1.25} pageNumber={1} />
        </Document>
      </Viewer>
      <Marginer margin="1em" direction="vertical" />
      <Viewer>
        <Document
          file={pragmatic}
          onLoadError={console.error}
          className="pdf-viewer"
          loading={<Loader />}
          options={{ workerSrc: '/pdf.worker.js' }}
        >
          <BodyTitle>Pragmatic Management Certificate</BodyTitle>
          <Marginer margin="1em" direction="vertical" />
          <Page pageNumber={1} />
        </Document>
      </Viewer>
      <Marginer margin="4em" direction="vertical" />
    </>
  );
};
