import styled from 'styled-components';
import { colors, dimensions } from '@styles/theme';

export const GameContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4em;
  justify-content: center;
  height: 100%;
  background-color: ${colors.background};
  padding-top: 4em;
  flex-wrap: nowrap;

  @media screen and (max-width: 480px) {
    flex-wrap: wrap;
  }
`;

export const CanvasContainer = styled.div`
  display: flex;
  flex-direction: column;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.25);
`;

export const GameTitle = styled.h2`
  color: ${colors.brand};
  font-size: 2em;
  margin-bottom: 0;
`;
