import styled from 'styled-components';
import { Element } from 'react-scroll';
import { colors, dimensions } from '@styles/theme';

export const PageSection = styled(Element)`
  width: 70%;
  min-height: calc(100vh - ${dimensions.navHeight});
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${colors.background};
  margin: 0 auto;

  @media screen and (max-width: 480px) {
    width: 90%;
  }
`;

export const PageSectionWide = styled(PageSection)`
  width: 100%;
`;
