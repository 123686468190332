import React, { useEffect, useState } from 'react';
import { BannerText } from '@styles/globalStyles';
import { CyclingText, StaticText } from './textCycleStyles';
import './textCycleStyles.tsx';

interface TextProps {
  beforeText: string;
  cycleText: string[];
  afterText: string;
}

export const TextCycle = ({ beforeText, cycleText, afterText }: TextProps) => {
  const [current, setCurrent] = useState(0);

  const divs = cycleText.map((text) => <CyclingText>{text}</CyclingText>);

  useEffect(() => {
    const timer = setTimeout(() => {
      setCurrent(current === cycleText.length - 1 ? 0 : current + 1);
    }, 3000);

    return () => clearTimeout(timer);
  }, [current]);

  return (
    <div>
      {beforeText && beforeText.length > 0 && (
        <StaticText>{beforeText}</StaticText>
      )}
      {divs[current]}
      <StaticText>{afterText}</StaticText>
    </div>
  );
};
