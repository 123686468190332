import React from 'react';
import {
  CardBackgroundFilter,
  CardScreen,
  ProjectCardFixedContainer,
} from '@components/ProjectCard/projectCardStyles';

interface GameCardProps {
  children: any;
  bgImg: string;
  game: string;
  c1: string;
  c2: string;
  handleGameSelect: (name: string) => void;
}

export const GameCard = ({
  children,
  bgImg,
  game,
  c1,
  c2,
  handleGameSelect,
}: GameCardProps) => {
  return (
    <ProjectCardFixedContainer
      c1={c1}
      c2={c2}
      onClick={() => handleGameSelect(game)}
    >
      <CardScreen className="screen" backgroundUrl={bgImg} />
      <CardBackgroundFilter>{children}</CardBackgroundFilter>
    </ProjectCardFixedContainer>
  );
};
