import { scroller } from 'react-scroll';
import { dimensions } from '@styles/theme';

export const scrollToSection = (sectionName: string, containerId?: string) => {
  scroller.scrollTo(sectionName, {
    smooth: true,
    offset: -parseInt(dimensions.navHeight, 10),
    containerId,
  });
};

export const scrollToElementById = (id: string) => {
  const ele = document.getElementById(id);
  console.log(ele);
  if (ele) {
    ele.scrollIntoView({ behavior: 'smooth' });
  }
};
