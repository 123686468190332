import React from 'react';
import { NavbarProps } from '.';
import { useSpring } from 'react-spring';
import { CollapseNavLinks, CollapseWrapper } from './navigationStyles';

export const CollapseMenu = ({ handleNavbar, navbarState }: NavbarProps) => {
  const { open } = useSpring({ open: navbarState ? 0 : 1 });

  if (navbarState) {
    return (
      <CollapseWrapper
        style={{
          zIndex: 10,
          transform: open
            .interpolate({
              range: [0, 0.2, 0.3, 1],
              output: [0, -20, 0, -200],
            })
            .interpolate((openValue) => `translate3d(0, ${openValue}px, 0)`),
        }}
      >
        <CollapseNavLinks>
          <li>
            <a href="/" onClick={handleNavbar}>
              home
            </a>
          </li>
          <li>
            <a href="/arcade" onClick={handleNavbar}>
              paradox
            </a>
          </li>
          <li>
            <a href="/about" onClick={handleNavbar}>
              about
            </a>
          </li>
          <li>
            <a href="/resume" onClick={handleNavbar}>
              resume
            </a>
          </li>
        </CollapseNavLinks>
      </CollapseWrapper>
    );
  }
  return null;
};
