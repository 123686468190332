import styled from 'styled-components';
import { colors } from '@styles/theme';

export const StaticText = styled.h2`
  float: left;
  color: ${colors.background};
  margin: 0 8px 0 0;
  line-height: 1.5em;
`;

export const CyclingText = styled.h2`
  margin: 0 0 0 4px;
  animation: slideIn 3s ease-in-out forwards;
  animation-iteration-count: 1;
  float: left;
  color: ${colors.brand};
  line-height: 1.5em;

  @keyframes slideIn {
    0% {
      margin-left: 8px;
      opacity: 0;
    }
    100% {
      margin-left: 0px;
      opacity: 1;
    }
  }
`;
