import { animated } from 'react-spring';
import styled, { css } from 'styled-components';
import { colors, dimensions, layers } from '@styles/theme';

/** Navbar */

export const Logo = styled.svg`
  height: 16px;
  width: 16px;
  color: black;
  src: ${({ src }: { src: string }) => src};
`;

export const NavBar = styled(animated.nav)`
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  background: ${colors.background};
  z-index: ${layers.top};
  font-size: 1rem;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
`;

export const FlexContainer = styled.div`
  max-width: 120rem;
  display: flex;
  margin: auto;
  padding: 0 2rem;
  justify-content: space-between;
  height: ${dimensions.navHeight};
`;

export const LogoLink = styled.div`
  height: 32px;
  width: 36px;
`;

export const NavLinks = styled(animated.ul)`
  justify-self: end;
  list-style-type: none;
  ${({ center }: { center?: boolean }) =>
    center &&
    css`
      margin: auto auto;
    `};
  padding: 0;
  display: flex;
  flex-direction: row;

  & span,
  a {
    color: ${colors.text};
    font-weight: 400;
    border-bottom: 1px solid transparent;
    margin: 0 2rem;
    transition: all 300ms linear 0s;
    text-decoration: none;
    cursor: pointer;
    line-height: 30px;

    &:hover {
      color: ${colors.brand};
      border-bottom: 1px solid ${colors.brand};
    }

    @media (max-width: 768px) {
      display: ${({ center }: { center?: boolean }) =>
        center ? 'none' : 'block'};
    }

    &.selected {
      font-weight: 600;
    }
  }
`;

export const BurgerWrapper = styled.div`
  margin: auto 0;

  @media (min-width: 769px) {
    display: none;
  }
`;

/** CollapseMenu */

export const CollapseWrapper = styled(animated.div)`
  background: ${colors.background};
  position: fixed;
  top: ${dimensions.navHeight};
  left: 0;
  right: 0;
`;

export const CollapseNavLinks = styled.ul`
  list-style-type: none;
  padding: 2rem 1rem 2rem 2rem;

  & li {
    transition: all 300ms linear 0s;
  }

  & span,
  a {
    font-size: 1rem;
    line-height: 2;
    color: ${colors.text};
    text-transform: uppercase;
    text-decoration: none;
    cursor: pointer;

    &:hover {
      color: ${colors.brand};
      border-bottom: 1px solid ${colors.brand};
    }
  }
`;

/** BurgerMenu */
export const Wrapper = styled.div`
  position: relative;
  cursor: pointer;
  display: block;
  padding-top: 0.4em;

  & span {
    background: ${colors.brand};
    display: block;
    position: relative;
    width: 2rem;
    height: 0.2rem;
    margin-bottom: 0.4rem;
    transition: all ease-in-out 0.2s;
  }

  .open span:nth-child(2) {
    opacity: 0;
  }

  .open span:nth-child(3) {
    transform: rotate(45deg);
    top: -11px;
  }

  .open span:nth-child(1) {
    transform: rotate(-45deg);
    top: 11px;
  }
`;

// Auto Scrolling
export const PageDownContainer = styled.div`
  position: absolute;
  top: ${({ section }: { section: number }) => `calc(100vh * ${section})`};
  left: 50%;
  transform: translate(-50%, -200%);
`;
