export const colors = {
  background: '#FDFDFD',
  brand: '#17ca75',
  text: '#26252B',
  white: '#FFFFFF',
  offText: '#4D464C',
  offBackground: '#838582',
  success: '#3CBD5C',
  warning: '#B9AD24',
  danger: '#F44336',
  xpert: '#963cbd',
};

export const dimensions = {
  navHeight: '40px',
  iconButton: '40px',
  mainRowGap: '4em',
  buttonHeight: '40px',
};

export const animations = {
  smoothTransition: 'all 220ms ease-in-out',
};

export const effects = {
  interactiveShadow: '4px 4px 12px rgba(0, 0, 0, 0.25)',
  hoverShadow: '8px 8px 16px rgba(0, 0, 0, 0.30)',
  hoverFilter: 'brightness(120%)',
};

export const layers = {
  base: 1,
  middle: 2,
  top: 10,
};
