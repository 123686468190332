import React, { useEffect } from 'react';
import { ProjectSection } from './ProjectSection';
import LogoUrl from '@assets/images/RmLogo.png';
import BackgroundImg from '@assets/images/bonnie-bg.jpg';
import { TopSection } from '@sections/TopSection';
import { ProjectCard } from '@components/ProjectCard';
import {
  ProjectCardHeader,
  ProjectDescription,
  ProjectSubTitle,
  ProjectTags,
  ProjectTitle,
} from '@components/ProjectCard/projectCardStyles';
import { Footer } from '@components/Footer';
import { BannerText } from '@styles/globalStyles';
import { TextCycle } from '@components/TextCycle';
import { ArrowDown, Repeat } from 'react-feather';
import styled from 'styled-components';
import { colors } from '@styles/theme';
import IxCloud from '@assets/images/ix-study/screen.png';

const featuredContent = (
  <ProjectCard
    c1={colors.xpert}
    c2={colors.brand}
    previewImg={IxCloud}
    path="/inspectionxpert"
    featured
  >
    <ProjectCardHeader>
      <ProjectTitle>InspectionXpert</ProjectTitle>
      <ProjectSubTitle>Web Application</ProjectSubTitle>
      <ProjectTags>Case Study | Product Designer | Developer</ProjectTags>
    </ProjectCardHeader>
    <ProjectDescription>
      A cloud-based rebuild of a legacy windows product used for digitizing
      engineering drawings through feature extraction and inspection plan report
      generation.
    </ProjectDescription>
  </ProjectCard>
);

const Subheader = styled.h3`
  display: flex;
  flex-direction: row;
  gap: 0.5em;
  color: ${colors.brand};
  align-items: center;

  p {
    margin: 0;
  }

  @media screen and (max-width: 480px) {
    display: none;
  }
`;

const welcomeContent = (
  <>
    <BannerText>Dream, Design, Develop</BannerText>
    <TextCycle
      beforeText="I build"
      cycleText={['Solutions', 'Experiences', 'for People']}
      afterText="."
    />
    <Subheader>
      <p>This is Bonnie</p>
      <ArrowDown />
    </Subheader>
  </>
);

export const Home = () => {
  useEffect(() => {
    const timer = setTimeout(() => {
      const container = document.getElementById('page-wrapper');
      if (container) container.scrollTo({ top: 0, left: 0 });
    }, 100);

    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      <TopSection
        backgroundUrl={BackgroundImg}
        logoUrl={LogoUrl}
        buttonText="Browse all projects"
        nextSectionName="projects"
        featuredContents={featuredContent}
        welcomeContents={welcomeContent}
      />
      <ProjectSection />
      <Footer />
    </>
  );
};
