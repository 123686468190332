import React from 'react';
import { AlchemonDocs } from './AlchemonDocs';
import { GameContainer } from '../paradoxStyles';
import { AlchemonGame } from './AlchemonGame';
import { PageSection } from '@pages/home/homeStyles';

export const Alchemon = () => {
  return (
    <PageSection name="alchemon">
      <GameContainer>
        <AlchemonGame />
        <AlchemonDocs />
      </GameContainer>
    </PageSection>
  );
};
