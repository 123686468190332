import React, { useRef, useEffect, useState } from 'react';
import { main, gameHeight, gameWidth } from './rasterize';
import './earthDefense.css';
import { GameContainer } from '../paradox/paradoxStyles';
import { PageSectionWide } from '@pages/home/homeStyles';
import { Instructions } from '@components/Instructions';
import { useHistory } from 'react-router-dom';

const instructions = [
  'To play:',
  'Spacebar - Fire missile',
  'p - To start game at the Game Start Screen',
  'w - Rotate upward around Earth',
  's - Rotate downward around Earth',
  'a - Rotate left around Earth',
  'd - Rotate right around Earth',
  'UpArrow / DownArrow - Select different Stations',
  'r - Restart game',
];

export const EarthDefense = () => {
  const history = useHistory();
  const imgCanvasRef = useRef(null);
  const webGLCanvasRef = useRef(null);

  const [playing, setPlaying] = useState(false);

  const updateScoreboard = () => {
    const scoreEle = document.getElementById('score');
    if (scoreEle) scoreEle.innerHTML = 'Score: '; // + score;
    const shieldEle = document.getElementById('shield');
    if (shieldEle) shieldEle.innerHTML = 'Shield: '; // + shield_level;
    const stationEle1 = document.getElementById('station1');
    if (stationEle1) stationEle1.innerHTML = 'Station Alpha: ' + 10;
    const stationEle2 = document.getElementById('station2');
    if (stationEle2) stationEle2.innerHTML = 'Station Bravo: ' + 10;
    const stationEle3 = document.getElementById('station3');
    if (stationEle3) stationEle3.innerHTML = 'Station Charlie: ' + 10;
    const earthEle = document.getElementById('earth');
    if (earthEle) earthEle.innerHTML = 'Earth: ' + 50;
    const selectedEle = document.getElementById('selected');
    if (selectedEle) selectedEle.innerHTML = 'Selected Station: Alpha';
    const stationChargeEle1 = document.getElementById('station1charge');
    if (stationChargeEle1) stationChargeEle1.innerHTML = 'Ready to Fire!';
    const stationChargeEle2 = document.getElementById('station2charge');
    if (stationChargeEle2) stationChargeEle2.innerHTML = 'Ready to Fire!';
    const stationChargeEle3 = document.getElementById('station3charge');
    if (stationChargeEle3) stationChargeEle3.innerHTML = 'Ready to Fire!';
    const loadingEle = document.getElementById('loading');
    if (loadingEle) loadingEle.innerHTML = 'Loading . . . Please Wait';
  };

  const handleKeyPress = (event: any) => {
    switch (event.code) {
      case 'ArrowUp':
        break;
      case 'ArrowDown':
        break;
      case 'KeyP':
        setPlaying(true);
        break;
      case 'Space':
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    main();
    updateScoreboard();
    window.addEventListener('onkeypress', handleKeyPress);

    return () => {
      window.removeEventListener('onkeypress', handleKeyPress);
      history.go(0);
    };
  }, [playing]);

  return (
    <PageSectionWide name="asteroids">
      <GameContainer>
        <div className="earth-defense-container">
          <div className="canvas-containers">
            <canvas
              id="myImageCanvas"
              className={`canvas ${playing ? 'bottom' : 'top'}`}
              ref={imgCanvasRef}
              height={gameHeight}
              width={gameWidth}
            />
            <canvas
              id="myWebGLCanvas"
              className={`canvas ${playing ? 'top' : 'bottom'}`}
              ref={webGLCanvasRef}
              height={gameHeight}
              width={gameWidth}
            />
          </div>
          <div className="footer">
            <p id="selected" className="selected"></p>
            <section className="stats">
              <section className="status">
                <p id="score" className="score"></p>
                <p id="shield" className="shield"></p>
                <p id="earth" className="earth"></p>
              </section>
              <section className="station-health">
                <p id="station1" className="alpha"></p>
                <p id="station2" className="bravo"></p>
                <p id="station3" className="charlie"></p>
              </section>
              <section className="station-charges">
                <p id="station1charge" className="station1charge"></p>
                <p id="station2charge" className="station2charge"></p>
                <p id="station3charge" className="station3charge"></p>
              </section>
            </section>
            <p id="loading" className="loading"></p>
          </div>
        </div>
        <Instructions
          title="Earth Defense"
          link="https://github.com/rphmee/My-Website/tree/main/src/pages/arcade/games/EarthDefense"
          instructions={instructions}
        />
      </GameContainer>
    </PageSectionWide>
  );
};
