import React from 'react';
import { Button } from '@components/Button';
import { Logo } from '@components/Logo';
import {
  ColumnSection,
  ContainerBackgroundFilter,
  FeatureContainer,
  IntroContainer,
  IntroMessageContainer,
  TopContainer,
  TopContentContainer,
} from './topSectionStyles';
import { scrollToElementById, scrollToSection } from '@utilities/navHelper';
import { ArrowDownCircle } from 'react-feather';

interface TopProps {
  logoUrl: string;
  backgroundUrl: string;
  buttonText: string;
  nextSectionName: string;
  welcomeContents: any;
  featuredContents?: any;
  variant?: string;
}

export const TopSection = ({
  logoUrl,
  backgroundUrl,
  buttonText,
  nextSectionName,
  featuredContents,
  welcomeContents,
  variant,
}: TopProps) => {
  return (
    <TopContainer backgroundUrl={backgroundUrl}>
      <ContainerBackgroundFilter>
        <TopContentContainer>
          <IntroContainer>
            <Logo logoUrl={logoUrl} variant={variant} />
            <ColumnSection>
              <IntroMessageContainer>{welcomeContents}</IntroMessageContainer>
              {!featuredContents && (
                <Button
                  variant="primary"
                  text={buttonText}
                  onClick={() => scrollToElementById(nextSectionName)}
                  icon={<ArrowDownCircle height={20} width={20} />}
                />
              )}
            </ColumnSection>
          </IntroContainer>
          {featuredContents && (
            <FeatureContainer>
              {featuredContents}
              <Button
                variant="wide"
                text={buttonText}
                onClick={() => scrollToElementById(nextSectionName)}
                icon={<ArrowDownCircle height={20} width={20} />}
              />
            </FeatureContainer>
          )}
        </TopContentContainer>
      </ContainerBackgroundFilter>
    </TopContainer>
  );
};
