import styled from 'styled-components';
import { animations, colors, effects, layers } from '@styles/theme';

export const ProjectCardHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: flex-start;
  width: 100%;
`;

export const ProjectTitle = styled.h2`
  font-size: 1.75em;
  width: 100%;
  margin: 0;
  text-align: left;
  color: ${colors.brand};
`;

export const ProjectSubTitle = styled.h3`
  font-size: 1em;
  width: 100%;
  margin: 0;
  text-align: left;
  color: ${colors.background};
`;

export const ProjectDescription = styled.p`
  font-size: 1em;
  line-height: 1.5em;
  text-align: justify;
  color: ${colors.background};
  margin: 0;

  @media screen and (max-width: 1200px) {
    font-size: 1em;
  }

  @media screen and (max-width: 480px) {
    max-width: 90vw;
    height: fit-content;
  }
`;

export const ProjectTags = styled.p`
  font-size: 1em;
  text-align: left;
  width: 100%;
  color: ${colors.background};
  margin: 0;
`;

export const CardScreen = styled.div`
  background-image: ${({ backgroundUrl }: { backgroundUrl: string }) =>
    `url(${backgroundUrl})`};
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  transition: ${animations.smoothTransition};
  margin: auto;
  height: 75%;
  width: 75%;
`;

export const CardBackgroundFilter = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(55, 55, 55, 0.65);
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: inherit;
  padding: 36px 36px;
  gap: 24px;
  backdrop-filter: blur(2px);
  opacity: 0;
  transition: ${animations.smoothTransition};
  position: absolute;
  left: 0;
  top: 0;
  z-index: 5;

  &:hover {
    opacity: 1;
  }

  @media screen and (max-width: 480px) {
    opacity: 1;
  }
`;

export const FeatureHeader = styled.div`
  position: absolute;
  z-index: ${layers.middle};
  top: 0;
  width: 100%;
  background-color: ${colors.brand};
  color: ${colors.background};
  text-align: center;
  font-weight: 500;
  font-size: 1.25em;
  line-height: 1.5em;
`;

export const ProjectCardContainer = styled.div`
  min-width: 45%;
  width: 100%;
  height: 360px;
  background:  ${({ c1, c2 }: { c1: string; c2: string }) =>
    `linear-gradient(45deg, ${c1}, ${c2})`}};
  transition: ${animations.smoothTransition};
  cursor: pointer;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-directon: column;
  justify-content: center;
  align-items: center;

  &:hover {
    box-shadow: ${effects.hoverShadow};
    filter: ${effects.hoverFilter};

    .screen {
      opacity: 0.25;
    }
  }

  @media screen and (max-width: 480px) {
    max-width: 90vw;
    min-width: 90vw;
  }
`;

export const ProjectCardFixedContainer = styled(ProjectCardContainer)`
  width: 50%;
  flex: 2 2;
`;

export const ProjectCardNDA = styled(ProjectCardFixedContainer)`
  cursor: not-allowed;
`;
