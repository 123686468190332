import React, { useEffect } from 'react';
import { SectionHeader } from '@styles/globalStyles';
import { PageSection } from '@pages/home/homeStyles';
import ProfileImg from '@assets/images/profile.jpg';
import { colors } from '@styles/theme';
import { ProfileContainer, ProfileImage } from './aboutStyles';
import { Footer } from '@components/Footer';
import {
  BodyColumn,
  BodyContainer,
  BodyCopy,
  BodyRow,
  BodySection,
} from '@components/Article/articleStyles';
import { ArticleNav } from '@components/ArticleNav';

export const About = () => {
  useEffect(() => {
    const timer = setTimeout(() => {
      const container = document.getElementById('page-wrapper');
      if (container) container.scrollTo({ top: 0, left: 0 });
    }, 100);

    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      <PageSection name="about">
        <BodyContainer>
          <BodySection bgColor={colors.background}>
            <BodyRow>
              <ProfileContainer>
                <ProfileImage src={ProfileImg} />
              </ProfileContainer>
              <BodyColumn>
                <SectionHeader>
                  Who am I? (See, <span className="left">Left</span>
                  <span className="above">Above</span>)
                </SectionHeader>
                <BodyCopy>
                  I'm a builder who enjoys the process of working through
                  problems and creating something that can be used by others to
                  improve their lives whether professionally or personally.
                  Taking ideas from the mind and guiding them into products is
                  an exciting journey. When I'm looking to have some fun or
                  relax I try to get out on trips (Hiking, Snowboarding, Road
                  Trips, etc.), catch a game with some friends (Wolfpack, Mets,
                  Knicks, Jets, Rangers...its built character), or just kick
                  back and watch some TV or get in some gaming.
                </BodyCopy>
              </BodyColumn>
            </BodyRow>
          </BodySection>

          <BodySection bgColor={colors.brand}>
            <SectionHeader>Where have I been?</SectionHeader>
            <BodyCopy>
              My professional career hasn't been a straight line. It began with
              an internship at the Bronx Zoo's ornithology department which led
              me into a Zoology program that I completed in 2012 at North
              Carolina State University. I worked at as a veterinary assistant
              and as a dog walker for the next couple of years. While my passion
              for wildlife and the environment remains strong, it became clear
              that it wasn't what I wanted in a career. I pivoted to programming
              and returned to NCSU where I completed their Master of Computer
              Science program in 2018.
            </BodyCopy>
          </BodySection>

          <BodySection bgColor={colors.background}>
            <SectionHeader>Where am I now?</SectionHeader>
            <BodyCopy>
              During my program I found a QA internship at InspectionXpert, a
              small quasi-startup based out of Raleigh, North Carolina. It was
              the first position I'd have in the tech industry and afforded me
              the opportunity to grow. As a small company I had the chance to
              try on different hats and after graduating I signed on as a full
              time employee. The company had an ambition to translate their
              windows software into a cloud-based solution and I took on the
              role of Product Designer where I carried the idea forward into
              something we could build. I've been able to bounce back and forth
              between designer and developer which has been an incredible
              experience for me. Drawing up the blueprints and then putting
              hammer to nail has taught me so much from differing perspectives.
            </BodyCopy>
          </BodySection>

          <BodySection bgColor={colors.brand}>
            <SectionHeader>Where am I going?</SectionHeader>
            <BodyCopy>
              I'm going to continue building things that can help solve real
              problems for people out in the world, and hopefully build
              something nice for myself at home. As for the rest? Who knows!
            </BodyCopy>
          </BodySection>
        </BodyContainer>
      </PageSection>
      <ArticleNav
        prevText="Paradox Games"
        prevPath="/arcade"
        nextText="My Resume"
        nextPath="/resume"
      />
      <Footer />
    </>
  );
};
