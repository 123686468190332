import React from 'react';
import { Instructions } from '@components/Instructions';

const instructions = [
  'Click an object to pick it up and click in the game zone to place it.',
  'Drop objects on top of each other to see if they combine.',
  'Use pokeballs on pokemon to catch them.',
  'Combine two pokemon of the same kind to evolve them.',
  'Try to catch them all!',
];

export const AlchemonDocs = () => {
  return (
    <Instructions
      instructions={instructions}
      link="https://github.com/rphmee/My-Website/tree/main/src/pages/arcade/games/paradox/Alchemon"
      title="AlcheMon!"
    />
  );
};
