import React, { useState } from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
import { Home } from './pages/home';
import { Navbar } from './components/Navigation';
import './App.scss';
import { Arcade } from './pages/arcade';
import { ProjectContainer } from './styles/globalStyles';
import { InspectionXpert } from './pages/InspectionXpert';
import { Alchemon } from './pages/arcade/games/paradox/Alchemon';
import { Snake } from './pages/arcade/games/paradox/Snake';
import { Asteroids } from './pages/arcade/games/paradox/Asteroids';
import { Cops } from './pages/arcade/games/paradox/Cops';
import { EarthDefense } from './pages/arcade/games/EarthDefense';
import { About } from './pages/about';
import { Resume } from './pages/resume';
import { dimensions } from '@styles/theme';
import { Marginer } from '@components/Marginer';
import styled from 'styled-components';
import { InspectionReports } from '@pages/InspectionReports';

const PageWrapper = styled.div`
  height: calc(100vh - ${dimensions.navHeight});
  width: 100%;
  overflow-y: auto;
`;

const App = () => {
  const [navbarOpen, setNavbarOpen] = useState(false);

  const handleNavbar = () => {
    setNavbarOpen(!navbarOpen);
  };
  return (
    <BrowserRouter>
      <Navbar navbarState={navbarOpen} handleNavbar={handleNavbar} />
      <Marginer direction="vertical" margin={dimensions.navHeight} />
      <PageWrapper id="page-wrapper">
        <ProjectContainer>
          <Route exact path="/" component={Home} />
          <Route exact path="/arcade" component={Arcade} />
          <Route exact path="/about" component={About} />
          <Route exact path="/resume" component={Resume} />
          <Route exact path="/arcade/alchemon" component={Alchemon} />
          <Route exact path="/arcade/asteroids" component={Asteroids} />
          <Route exact path="/arcade/cops" component={Cops} />
          <Route exact path="/arcade/earth" component={EarthDefense} />
          <Route exact path="/arcade/snake" component={Snake} />
          <Route path="/inspectionxpert" component={InspectionXpert} />
          <Route path="/inspectionreports" component={InspectionReports} />
        </ProjectContainer>
      </PageWrapper>
    </BrowserRouter>
  );
};

export default App;
