import styled from 'styled-components';

// Margins
export const HorizontalMargin = styled.span`
  display: flex;
  width: ${({ margin }: { margin: string | number }) =>
    typeof margin === 'string' ? margin : `${margin}px`};
`;

export const VerticalMargin = styled.span`
  display: flex;
  height: ${({ margin }: { margin: string | number }) =>
    typeof margin === 'string' ? margin : `${margin}px`};
`;
