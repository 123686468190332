import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import { Footer } from '@components/Footer';
import { GallerySection } from './GallerySection';
import { ArticleNav } from '@components/ArticleNav';
import { SiteLink } from '@components/Article/articleStyles';
import { GitHub } from 'react-feather';
import { Marginer } from '@components/Marginer';

export const Arcade = () => {
  const history = useHistory();

  const handleGameSelect = (name: string) => {
    history.push(`/arcade/${name}`);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      const container = document.getElementById('page-wrapper');
      if (container) container.scrollTo({ top: 0, left: 0 });
    }, 100);

    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      <GallerySection handleGameSelect={handleGameSelect} />;
      <SiteLink href="https://github.com/rphmee/paradox" target="_blank">
        Paradox GitHub Repository
        <GitHub />
      </SiteLink>
      <Marginer direction="vertical" margin="2em" />
      <ArticleNav
        prevText="Inspection Reports"
        prevPath="/inspectionreports"
        nextText="About Me"
        nextPath="/about"
      />
      <Footer />
    </>
  );
};
