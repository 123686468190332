import React from 'react';
import { Instructions } from '@components/Instructions';

const instructions = [
  'Left click or press space to fire.',
  'Hold right click to accelerate towards the cursor.',
  'Click Q to use rapid fire.',
  'Use Shift to slow down time.',
  'Press U to upgrade shot when you have enoug money',
  'Gain one health per level.',
  'Destroy as many asteroids as possible!',
];

export const AsteroidsDocs = () => {
  return (
    <Instructions
      instructions={instructions}
      title="Asteroid Run"
      link="https://github.com/rphmee/My-Website/tree/main/src/pages/arcade/games/paradox/Asteroids"
    />
  );
};
