import styled from 'styled-components';
import { animations, colors } from '@styles/theme';

export const DocsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  min-width: 12em;
  gap: 1em;
`;

export const InstructionTitle = styled.h3`
  font-size: 1.15em;
  line-height: 1.5em;
  text-transform: uppercase;
  width: 100%;
  color: ${colors.text};
`;

export const Instruction = styled.li`
  color: ${colors.text};
  font-size: 1em;
  margin: 1em 0;
`;

export const InstructionLink = styled.a`
  text-decoration: none;
  color: ${colors.brand};
  width: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5em;
  transition: ${animations.smoothTransition};

  svg {
    margin-top: 4px;
  }

  &:hover {
    filter: brightness(120%);
  }
`;
