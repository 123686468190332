import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import styled from 'styled-components';
import './carouselStyles.css';

interface CarouselProps {
  gifs: { src: string; label: string }[];
}

const CarWrapper = styled.div`
  width: 80%;
  margin: auto auto;
`;

export const CarouselWrapper = ({ gifs }: CarouselProps) => {
  return (
    <CarWrapper>
      <Carousel showThumbs={false} showArrows={true}>
        {gifs.map((gif: { src: string; label: string }, index) => (
          <div key={`${gif.label}-${index}-key`}>
            <img alt={gif.label} src={gif.src} />
            <p className="legend">{gif.label}</p>
          </div>
        ))}
      </Carousel>
    </CarWrapper>
  );
};
