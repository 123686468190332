import React from 'react';
import { Instructions } from '@components/Instructions';

const instructions = [
  'Press an arrow key to make your move.',
  'Corner a Robber with two Cops to catch them.',
  'Robbers win after the turn count hits zero.',
  'Cops win if all Robbers are caught.',
];

export const CopsDocs = () => {
  return (
    <Instructions
      instructions={instructions}
      title="Cops or Robbers"
      link="https://github.com/rphmee/My-Website/tree/main/src/pages/arcade/games/paradox/Cops"
    />
  );
};
