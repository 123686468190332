import React from 'react';
import earthIcon from '@assets/images/earth-preview.png';
import alchIcon from '@assets/images/alchemon-preview.png';
import snakeIcon from '@assets/images/snake-preview.png';
import copsIcon from '@assets/images/cops-preview.png';
import asteroidsIcon from '@assets/images/asteroids-preview.png';
import { ProjectsContainer } from '@components/ProjectGallery/projectGalleryStyles';
import {
  ProjectCardHeader,
  ProjectDescription,
  ProjectTags,
  ProjectTitle,
} from '@components/ProjectCard/projectCardStyles';
import { GameCard } from '@components/GameCard';
import { colors } from '@styles/theme';

interface GalleryProps {
  handleGameSelect: (name: string) => void;
}

export const GameGallery = ({ handleGameSelect }: GalleryProps) => {
  return (
    <ProjectsContainer>
      <GameCard
        bgImg={earthIcon}
        c1="skyblue"
        c2="seagreen"
        handleGameSelect={handleGameSelect}
        game="earth"
      >
        <ProjectCardHeader>
          <ProjectTitle>Earth Defense</ProjectTitle>
        </ProjectCardHeader>
        <ProjectTags>WebGL | Developer</ProjectTags>
        <ProjectDescription>
          An onslaught of asteroids threatens earth. Control the planet's
          asteroid defense system to prevent the apocalypse.
        </ProjectDescription>
      </GameCard>
      <GameCard
        c1={colors.danger}
        c2={colors.offBackground}
        bgImg={alchIcon}
        handleGameSelect={handleGameSelect}
        game="alchemon"
      >
        <ProjectCardHeader>
          <ProjectTitle>Alchemon</ProjectTitle>
        </ProjectCardHeader>
        <ProjectTags>Paradox Engine | Developer</ProjectTags>
        <ProjectDescription>
          Pokemon game based on Little Alchemy. Hatch and combine your pokemon
          to evolve them and Catch 'Em All.
        </ProjectDescription>
      </GameCard>
      <GameCard
        c1="forestgreen"
        c2="yellow"
        bgImg={snakeIcon}
        handleGameSelect={handleGameSelect}
        game="snake"
      >
        <ProjectCardHeader>
          <ProjectTitle>Snake</ProjectTitle>
        </ProjectCardHeader>
        <ProjectTags>Paradox Engine | Developer</ProjectTags>
        <ProjectDescription>
          The beloved classic, Snake! Eat all of the fresh red apples while
          avoiding the rotten greens.
        </ProjectDescription>
      </GameCard>
      <GameCard
        c1="blue"
        c2="red"
        bgImg={copsIcon}
        handleGameSelect={handleGameSelect}
        game="cops"
      >
        <ProjectCardHeader>
          <ProjectTitle>Cops and Robbers</ProjectTitle>
        </ProjectCardHeader>
        <ProjectTags>Paradox Engine | Developer</ProjectTags>
        <ProjectDescription>
          A turn-based game where you can run from AI controlled cops or chase
          down robbers. Randomized game board with A* pathfinding.
        </ProjectDescription>
      </GameCard>
      <GameCard
        c1={colors.xpert}
        c2={colors.brand}
        bgImg={asteroidsIcon}
        handleGameSelect={handleGameSelect}
        game="asteroids"
      >
        <ProjectCardHeader>
          <ProjectTitle>Asteroids</ProjectTitle>
        </ProjectCardHeader>
        <ProjectTags>Paradox Engine | Developer</ProjectTags>
        <ProjectDescription>
          The beloved classic, Asteroids! Navigate through the asteroid field
          while shooting down as many rocks as you can.
        </ProjectDescription>
      </GameCard>
    </ProjectsContainer>
  );
};
