import React from 'react';
import { Instructions } from '@components/Instructions';

const instructions = [
  'Collect the red apples to grow.',
  'Avoid the rotten green apples.',
  "Don't run into wall or yourself!",
  'Movement P1 (top left snake): arrow keys.',
  'Movement P2 (top right snake): w-a-s-d.',
  "Press 'R' to restart.",
];

export const SnakeDocs = () => {
  return (
    <Instructions
      instructions={instructions}
      link="https://github.com/rphmee/My-Website/tree/main/src/pages/arcade/games/paradox/Snake"
      title="Apple Snake"
    />
  );
};
