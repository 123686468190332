import React from 'react';
import { ArrowLeftCircle, ArrowRightCircle } from 'react-feather';
import { useHistory } from 'react-router-dom';
import {
  BottomNav,
  BottomNavButton,
  VerticalDivider,
} from './articleNavStyles';

interface ArticleNavProps {
  prevText: string;
  nextText: string;
  prevPath: string;
  nextPath: string;
}

export const ArticleNav = ({
  prevText,
  prevPath,
  nextText,
  nextPath,
}: ArticleNavProps) => {
  const history = useHistory();

  const handleNav = (path: string) => {
    history.push(path);
  };

  return (
    <BottomNav>
      <BottomNavButton onClick={() => handleNav(prevPath)} flex="flex-end">
        <ArrowLeftCircle height={16} width={16} />
        {prevText}
      </BottomNavButton>
      <VerticalDivider />
      <BottomNavButton onClick={() => handleNav(nextPath)} flex="flex-start">
        {nextText}
        <ArrowRightCircle height={16} width={16} />
      </BottomNavButton>
    </BottomNav>
  );
};
