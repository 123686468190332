import styled from 'styled-components';
import { colors } from '@styles/theme';

export const LogoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const IxLogoContainer = styled(LogoContainer)`
  background-color: #963cbd;
  height: fit-content;
  padding: 8px;
`;

export const LogoImg = styled.img`
  width: 10em;
  height: 10em;

  @media screen and (max-width: 480px) {
    width: 4em;
    height: 4em;
  }
`;

export const LogoText = styled.div`
  font-size: 3em;
  color: ${colors.brand};
  margin-top: 6px;
  font-weight: bold;
`;
