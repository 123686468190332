import React from 'react';
import { ArrowLeftCircle, GitHub, Info } from 'react-feather';
import { useHistory } from 'react-router';
import { Button } from '@components/Button';
import {
  DocsContainer,
  InstructionLink,
  InstructionTitle,
} from './instructionStyles';
import { GameTitle } from '@pages/arcade/games/paradox/paradoxStyles';
import {
  IconIcon,
  IconItemWide,
  IconListTall,
} from '@components/Article/articleStyles';

interface InstructionProps {
  title?: string;
  link?: string;
  instructions: string[];
}

export const Instructions = ({
  title,
  link,
  instructions,
}: InstructionProps) => {
  const history = useHistory();

  const handleBackToArcade = () => {
    history.push('/arcade');
  };
  return (
    <DocsContainer>
      <GameTitle>{title}</GameTitle>
      {link && (
        <InstructionLink href={link} target="_blank">
          GitHub Repository
          <GitHub size={16} />
        </InstructionLink>
      )}
      <InstructionTitle>Instructions</InstructionTitle>
      <IconListTall>
        {instructions.map((instruction, index) => (
          <div key={`${instruction.replaceAll(' ', '')}-${index}-section-key`}>
            <IconItemWide
              key={`${instruction.replaceAll(' ', '')}-${index}-key`}
            >
              <IconIcon>
                <Info height={16} width={16} />
              </IconIcon>
              {instruction}
            </IconItemWide>
          </div>
        ))}
      </IconListTall>
      <Button
        variant="primary"
        text="Back to arcade"
        icon={<ArrowLeftCircle />}
        onClick={handleBackToArcade}
      />
    </DocsContainer>
  );
};
