import React from 'react';
import { GitHub, Linkedin, Mail } from 'react-feather';
import { config, useSpring } from 'react-spring';
import { NavLinks } from '@components/Navigation/navigationStyles';
import {
  FooterContainer,
  FooterCopyright,
  FooterLine,
  FooterLinksContainer,
} from './footerStyles';

export const Footer = () => {
  const linkAnimation = useSpring({
    from: { transform: 'translate3d(0, 30px, 0)', opacity: 0 },
    to: { transform: 'translate3d(0, 0, 0)', opacity: 1 },
    delay: 800,
    config: config.wobbly,
  });

  return (
    <FooterContainer>
      <FooterLine />
      <FooterLinksContainer>
        <NavLinks style={linkAnimation}>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="mailto: rphmee@gmail.com"
          >
            <Mail />
          </a>
        </NavLinks>
        <NavLinks style={linkAnimation}>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.linkedin.com/in/ryan-mee-b39b7a78"
          >
            <Linkedin />
          </a>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://github.com/rphmee"
          >
            <GitHub />
          </a>
        </NavLinks>
      </FooterLinksContainer>
      <FooterCopyright>© Ryan Mee all rights reserved 2021</FooterCopyright>
    </FooterContainer>
  );
};
