import React from 'react';
import useScript from '@hooks/useScript';
import { engine } from '../paradoxEngine';
import { CanvasContainer } from '../paradoxStyles';
import { snakeScript } from './snakeScript';

export const SnakeGame = () => {
  useScript([
    engine,
    snakeScript,
    `preloadArtAssets('../assets/images/', IMAGES, initializeEngine);
    callbackOnEngineLoad(initializeGame);`,
  ]);

  return (
    <CanvasContainer>
      <canvas
        id="canvas"
        width="400"
        height="500"
        style={{ border: '1px solid #c3c3c3' }}
      >
        Your browser does not support the HTML5 canvas tag.
      </canvas>
    </CanvasContainer>
  );
};
