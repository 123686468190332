import styled from 'styled-components';
import { colors, dimensions } from './theme';

export const ProjectContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const MainContainer = styled.main`
  display: flex;
  flex-direction: column;
  padding: 10rem 20rem;
`;

export const NavigationMenu = styled.div`
  height: 0px;
  max-height: 0px;
`;

// Text
export const BannerText = styled.h1`
  margin: 0;
  color: ${colors.white};
  line-height: 1.75em;
`;

export const SectionHeader = styled.h3`
  color: ${colors.text};
  margin-top: ${dimensions.navHeight};
  text-transform: uppercase;
  width: 100%;

  .above {
    display: none;
    font-weight: 400;
    font-size: 1.25em;
    line-height: 1.75em;
  }

  .left {
    display: inline;
    font-weight: 400;
    font-size: 1.25em;
    line-height: 1.75em;
  }

  @media screen and (max-width: 480px) {
    width: 90%;
    padding: 1em 0;

    .above {
      display: inline;
    }

    .left {
      display: none;
    }
  }
`;
