import React from 'react';
import { useHistory } from 'react-router';
import {
  CardBackgroundFilter,
  CardScreen,
  FeatureHeader,
  ProjectCardContainer,
  ProjectCardFixedContainer,
  ProjectCardNDA,
} from './projectCardStyles';

export const ProjectCard = ({
  children,
  previewImg,
  c1,
  c2,
  path,
  featured,
  nda,
  external,
}: {
  children: any;
  previewImg: string;
  c1: string;
  c2: string;
  path: string;
  featured?: boolean;
  nda?: boolean;
  external?: boolean;
}) => {
  const history = useHistory();

  const handleCardClick = () => {
    if (external) {
      const win = window.open(path, '_blank');
      win?.focus();
    } else {
      history.push(path);
    }
  };

  const getComponent = () => {
    if (featured)
      return (
        <ProjectCardContainer c1={c1} c2={c2} onClick={handleCardClick}>
          {featured && <FeatureHeader>Featured project</FeatureHeader>}
          <CardScreen className="screen" backgroundUrl={previewImg} />
          <CardBackgroundFilter>{children}</CardBackgroundFilter>
        </ProjectCardContainer>
      );
    if (nda)
      return (
        <ProjectCardNDA c1={c1} c2={c2} onClick={() => {}}>
          {featured && <FeatureHeader>Featured project</FeatureHeader>}
          <CardScreen className="screen" backgroundUrl={previewImg} />
          <CardBackgroundFilter>{children}</CardBackgroundFilter>
        </ProjectCardNDA>
      );
    return (
      <ProjectCardFixedContainer c1={c1} c2={c2} onClick={handleCardClick}>
        {featured && <FeatureHeader>Featured project</FeatureHeader>}
        <CardScreen className="screen" backgroundUrl={previewImg} />
        <CardBackgroundFilter>{children}</CardBackgroundFilter>
      </ProjectCardFixedContainer>
    );
  };

  return getComponent();
};
