import { animations, colors } from '@styles/theme';
import styled from 'styled-components';

export const BottomNav = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 2em;
`;

export const BottomNavButton = styled.div`
  height: 3em;
  width: 300px;
  color: ${colors.brand};
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1em;
  justify-content: ${({ flex }: { flex: string }) => `${flex}`};
  font-size: 1em;
  transition: ${animations.smoothTransition};

  &:hover {
    filter: brightness(120%);
    cursor: pointer;
  }
`;

export const VerticalDivider = styled.div`
  height: 40px;
  margin: auto 2em;
  width: 1px;
  background-color: ${colors.offBackground};
`;
