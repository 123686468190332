import React from 'react';
import { IxLogoContainer, LogoContainer, LogoImg } from './logoStyles';

export const Logo = ({
  logoUrl,
  variant,
}: {
  logoUrl: string;
  variant?: string;
}) => {
  return (
    <>
      {variant ? (
        <IxLogoContainer>
          <LogoImg src={logoUrl} />
        </IxLogoContainer>
      ) : (
        <LogoContainer>
          <LogoImg src={logoUrl} />
        </LogoContainer>
      )}
    </>
  );
};
