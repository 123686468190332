import React from 'react';
import { ProjectCard } from '@components/ProjectCard';
import { ProjectsContainer } from './projectGalleryStyles';
import {
  ProjectCardHeader,
  ProjectDescription,
  ProjectSubTitle,
  ProjectTags,
  ProjectTitle,
} from '@components/ProjectCard/projectCardStyles';
import { colors } from '@styles/theme';
import IxCloud from '@assets/images/ix-study/screen.png';
import IxReports from '@assets/images/report-study/screen.png';
import Paradox from '@assets/images/paradox-preview.png';
import Metro from '@assets/images/ix-study/metro.png';
import Portfolio from '@assets/images/portfolio-preview.png';

export const ProjectGallery = () => {
  return (
    <ProjectsContainer>
      <ProjectCard
        c1={colors.xpert}
        c2={colors.brand}
        previewImg={IxCloud}
        path="/inspectionxpert"
      >
        <ProjectCardHeader>
          <ProjectTitle>InspectionXpert</ProjectTitle>
          <ProjectSubTitle>Web Application</ProjectSubTitle>
          <ProjectTags>Case Study | Product Designer | Developer</ProjectTags>
        </ProjectCardHeader>
        <ProjectDescription>
          A cloud-based rebuild of a legacy windows product used for digitizing
          engineering drawings through feature extraction and inspection plan
          report generation.
        </ProjectDescription>
      </ProjectCard>
      <ProjectCard
        c1="teal"
        c2="coral"
        previewImg={IxReports}
        path="/inspectionreports"
      >
        <ProjectCardHeader>
          <ProjectTitle>Inspection Reports</ProjectTitle>
          <ProjectSubTitle>Application Feature</ProjectSubTitle>
          <ProjectTags>Case Study | Product Designer | Developer</ProjectTags>
        </ProjectCardHeader>
        <ProjectDescription>
          A report template builder and report generation interface for
          InspectionXpert Cloud users.
        </ProjectDescription>
      </ProjectCard>
      <ProjectCard c1="pink" c2="skyblue" previewImg={Paradox} path="/arcade">
        <ProjectCardHeader>
          <ProjectTitle>Paradox Games</ProjectTitle>
          <ProjectSubTitle>Game Engine</ProjectSubTitle>
          <ProjectTags>Demo | Full Stack Developer</ProjectTags>
        </ProjectCardHeader>
        <ProjectDescription>
          A javascript game engine used to construct several games through a
          common set of game object management, rendering, collision, physics,
          and controller systems.
        </ProjectDescription>
      </ProjectCard>
      <ProjectCard
        c1="royalblue"
        c2={colors.warning}
        previewImg={Metro}
        path="/metro"
        nda
      >
        <ProjectCardHeader>
          <ProjectTitle>InspectionXpert - Metro</ProjectTitle>
          <ProjectSubTitle>Currently under NDA</ProjectSubTitle>
          <ProjectTags>Product Designer | Developer</ProjectTags>
        </ProjectCardHeader>
      </ProjectCard>
      <ProjectCard
        c1={colors.brand}
        c2="royalblue"
        previewImg={Portfolio}
        path="https://github.com/rphmee/My-Website"
        external
      >
        <ProjectCardHeader>
          <ProjectTitle>My Portfolio</ProjectTitle>
          <ProjectSubTitle>GitHub Repository</ProjectSubTitle>
          <ProjectTags>React Website | Developer</ProjectTags>
        </ProjectCardHeader>
      </ProjectCard>
    </ProjectsContainer>
  );
};
