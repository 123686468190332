import React from 'react';
import { GameGallery } from '@components/GameGallery';
import { Marginer } from '@components/Marginer';
import { SectionHeader } from '@styles/globalStyles';
import { PageSection } from '@pages/home/homeStyles';

interface GalleryProps {
  handleGameSelect: (name: string) => void;
}

export const GallerySection = ({ handleGameSelect }: GalleryProps) => {
  return (
    <PageSection name="gallery">
      <Marginer direction="vertical" margin="1em" />
      <SectionHeader>Paradox Games</SectionHeader>
      <GameGallery handleGameSelect={handleGameSelect} />
    </PageSection>
  );
};
