import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

const useScript = (code: string[]) => {
  const [scripts, setScripts] = useState<HTMLScriptElement[]>([]);
  const history = useHistory();

  const cleanScripts = () => {
    history.go(0);
  };

  useEffect(() => {
    if (code.length === scripts.length) return;
    const scriptArray: HTMLScriptElement[] = [];

    code.forEach((text) => {
      try {
        const script = document.createElement('script');
        script.async = true;
        const scriptText = document.createTextNode(text);
        script.appendChild(scriptText);

        document.body.appendChild(script);

        scriptArray.push(script);
      } catch (err) {
        console.log(err);
      }
    });

    setScripts(scriptArray);
  }, [code, scripts]);

  useEffect(() => {
    return () => {
      cleanScripts();
    };
  }, []);
};

export default useScript;
