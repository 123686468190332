import React from 'react';
import { ProjectGallery } from '@components/ProjectGallery';
import { SectionHeader } from '@styles/globalStyles';
import { PageSection } from '@pages/home/homeStyles';

export const ProjectSection = () => {
  return (
    <PageSection id="projects" name="projects">
      <SectionHeader>Projects</SectionHeader>
      <ProjectGallery />
    </PageSection>
  );
};
