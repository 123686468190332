import React from 'react';
import { useSpring, config } from 'react-spring';
import { BurgerMenu } from './BurgerMenu';
import { CollapseMenu } from './CollapseMenu';
import { useHistory, useLocation } from 'react-router-dom';
import {
  BurgerWrapper,
  FlexContainer,
  LogoLink,
  NavBar,
  NavLinks,
} from './navigationStyles';
import { LogoSvg } from '@assets/RM';

export interface NavbarProps {
  handleNavbar: () => void;
  navbarState: boolean;
}

export const Navbar = ({ handleNavbar, navbarState }: NavbarProps) => {
  const location = useLocation();
  const pathname = location.pathname;
  const history = useHistory();

  const barAnimation = useSpring({
    from: { transform: 'translate3d(0, -10rem, 0)' },
    transform: 'translate3d(0, 0, 0)',
  });

  const linkAnimation = useSpring({
    from: { transform: 'translate3d(0, 30px, 0)', opacity: 0 },
    to: { transform: 'translate3d(0, 0, 0)', opacity: 1 },
    delay: 800,
    config: config.wobbly,
  });

  const handleNav = (path: string) => {
    history.push(path);
  };

  return (
    <>
      <NavBar style={barAnimation}>
        <FlexContainer>
          <NavLinks center style={linkAnimation}>
            <span
              className={`${pathname === '/' ? 'selected' : 'not-selected'}`}
              onClick={() => handleNav('/')}
            >
              home
            </span>
            <span
              className={`${
                pathname.includes('arcade') ? 'selected' : 'not-selected'
              }`}
              onClick={() => handleNav('/arcade')}
            >
              paradox
            </span>
            <span
              className={`${pathname === '/' ? 'selected' : 'not-selected'}`}
              onClick={() => handleNav('/')}
            >
              <LogoLink>
                <LogoSvg />
              </LogoLink>
            </span>
            <span
              className={`${
                pathname.includes('about') ? 'selected' : 'not-selected'
              }`}
              onClick={() => handleNav('/about')}
            >
              about
            </span>
            <span
              className={`${
                pathname.includes('resume') ? 'selected' : 'not-selected'
              }`}
              onClick={() => handleNav('/resume')}
            >
              resumé
            </span>
          </NavLinks>
          <BurgerWrapper>
            <BurgerMenu navbarState={navbarState} handleNavbar={handleNavbar} />
          </BurgerWrapper>
        </FlexContainer>
      </NavBar>
      <CollapseMenu navbarState={navbarState} handleNavbar={handleNavbar} />
    </>
  );
};
