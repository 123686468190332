import { animations, colors, dimensions, effects } from '@styles/theme';
import styled from 'styled-components';

export const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1em 1em;
  gap: 2em;

  @media screen and (max-width: 480px) {
    width: 90%;
    padding: 1em 0;
  }
`;

export const BodyTitle = styled.h2`
  color: ${colors.text};
  font-size: 1.25em;
  line-height: 1.5em;
  text-transform: uppercase;
  width: 100%;
`;

export const BodySubtitle = styled.h3`
  color: ${colors.offText};
  font-size: 1em;
  line-height: 1.5em;
  text-transform: uppercase;
  width: 100%;
`;

export const BodyCopy = styled.p`
  color: ${colors.offText};
  font-size: 1em;
  line-height: 2em;
  text-align: justify;
  margin-top: 0;
  margin-bottom: 1em;
  flex: 1 1;

  @media screen and (max-width: 480px) {
    min-width: 90%;
  }
`;

export const BodyRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 4em;
  margin-bottom: 1em;
  flex-wrap: wrap;
`;

export const BodyColumn = styled.div`
  min-width: 30%;
  display: flex;
  flex-direction: column;
  gap: 1em;
  flex: 1 1;
`;

export const BodyColumnWide = styled(BodyColumn)`
  flex: 3 1;
`;

export const BodySection = styled.div`
  flex-direction: column;
  display: flex;
  background-color: ${({ bgColor }: { bgColor: string }) => `${bgColor}11`};
  padding: 1em;

  @media screen and (max-width: 480px) {
    padding: 1em 8px;
  }
`;

export const ImageContainer = styled.div`
  width: 100%;
  height: 240px;
  padding: 8px;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.25);
`;

export const BodyImage = styled.div`
  width: 100%;
  height: 100%;
  background-image: ${({ backgroundUrl }: { backgroundUrl: string }) =>
    `url(${backgroundUrl})`};
  background-position: center;
  background-size: cover;
`;

export const IconList = styled.div`
  width: inherit;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 1em;
  flex-wrap: wrap;
  margin-bottom: 0.5em;
`;

export const IconListTall = styled(IconList)`
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5em;
  flex-wrap: none;
`;

export const IconSublist = styled(IconListTall)`
  padding-left: 1.5em;
  padding-top: 0.5em;
`;

export const IconIcon = styled.span`
  color: ${colors.brand};
  margin: auto 0;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const IconItem = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
  line-height: 1.5em;
  width: 240px;
  gap: 0.5em;
`;

export const IconItemWide = styled(IconItem)`
  width: fit-content;
`;

export const SiteLink = styled.a`
  height: ${dimensions.buttonHeight};
  text-decoration: none;
  font-size: 1.25em;
  color: ${colors.brand};
  width: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1em;
  margin: auto auto;
  transition: ${animations.smoothTransition};

  &:hover {
    filter: brightness(120%);
  }
`;

export const TitleLogo = styled.img`
  height: 45px;
  width: 315px;

  @media screen and (max-width: 480px) {
    height: 40px;
    width: 210px;
  }
`;
