import styled from 'styled-components';
import { dimensions } from '@styles/theme';

export const AboutContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: ${dimensions.mainRowGap};

  @media screen and (max-width: 480px) {
    flex-direction: column;
  }
`;

export const ProfileBody = styled.div`
  display: flex;
  flex-direction: column;
  width: 70%;

  @media screen and (max-width: 480px) {
    width: 90%;
  }
`;

export const ProfileContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 30%;

  @media screen and (max-width: 480px) {
    width: 90%;
  }
`;

export const ProfileImage = styled.img`
  width: 100%;
`;
