import styled from 'styled-components';
import { animations, colors, dimensions, effects } from '@styles/theme';

const getVariantHoverStyles = (
  variant: 'primary' | 'secondary' | 'danger' | 'wide'
) => {};

const getVariantStyles = (
  variant: 'primary' | 'secondary' | 'danger' | 'wide'
) => {
  switch (variant) {
    case 'primary':
      return `
        background-color: ${colors.brand};
        color: ${colors.background};
        border: none;

        &:hover {
          background-color: lighten(${colors.brand}, 10%);
        }
      `;
    case 'secondary':
      return `
        background-color: ${colors.background};
        border: 1px solid ${colors.offText};
        color: ${colors.offText};
      `;
    case 'danger':
      return `
        background-color: ${colors.warning};
        border: none;
        color: ${colors.background};
      `;
    case 'wide':
      return `
          background-color: ${colors.brand};
          color: ${colors.background};
          border: none;
          width: 100%;
          justify-content: center;
          
          &:hover {
            background-color: lighten(${colors.brand}, 10%);
          }
        `;
    default:
      return `
        background-color: ${colors.brand};
        color: ${colors.background};
        border: none;
      `;
  }
};

export const ButtonWrapper = styled.button`
  display: flex;
  width: fit-content;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  padding: 6px 12px;
  height: ${dimensions.buttonHeight};
  cursor: pointer;
  outline: none;
  transition: ${animations.smoothTransition};
  ${({ variant }: { variant: 'primary' | 'secondary' | 'danger' | 'wide' }) =>
    getVariantStyles(variant)};

  &:hover {
    filter: ${effects.hoverFilter};
  }
`;

export const ButtonText = styled.span`
  font-size: 1.3em;
  font-weight: 500;
`;
