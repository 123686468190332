import React from 'react';
import { HorizontalMargin, VerticalMargin } from './marginerStyles';

interface MarginerProps {
  direction: 'horizontal' | 'vertical';
  margin: string | number;
}

export const Marginer = ({ direction, margin }: MarginerProps) => {
  return direction === 'horizontal' ? (
    <HorizontalMargin margin={margin} />
  ) : (
    <VerticalMargin margin={margin} />
  );
};
