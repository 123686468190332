import React from 'react';
import { NavbarProps } from '.';
import { Wrapper } from './navigationStyles';

export const BurgerMenu = ({ handleNavbar, navbarState }: NavbarProps) => {
  return (
    <Wrapper onClick={handleNavbar}>
      <div className={navbarState ? 'open' : ''}>
        <span>&nbsp;</span>
        <span>&nbsp;</span>
        <span>&nbsp;</span>
      </div>
    </Wrapper>
  );
};
