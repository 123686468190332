import styled from 'styled-components';

export const Viewer = styled.div`
  .react-pdf__Page__canvas {
    margin: 0 auto;
    width: 100% !important;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
  }
  @media screen and (max-width: 480px) {
    .react-pdf__Page__canvas {
      width: 100% !important;
      height: auto !important;
    }
  }
`;

export const PdfTools = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;
