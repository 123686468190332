import React from 'react';
import { PageSection } from '@pages/home/homeStyles';
import { GameContainer } from '../paradoxStyles';
import { SnakeDocs } from './SnakeDocs';
import { SnakeGame } from './SnakeGame';

export const Snake = () => {
  return (
    <PageSection name="asteroids">
      <GameContainer>
        <SnakeGame />
        <SnakeDocs />
      </GameContainer>
    </PageSection>
  );
};
